import { CircleFadingPlus, Diff, Fingerprint, LogIn, LogOut, Merge, Settings } from 'lucide-react';
import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import api from '../utils/api';

const ServerDetailPage = () => {
    const { guildId } = useParams();
    const [extensionsData, setExtensionsData] = React.useState<any>({});

    useEffect(() => {
        const fetchWelcomeData = async () => {
            const { data } = await api.get(`/welcome/${guildId}`);
            if (data?.status === 'success') {
                setExtensionsData((prev: any) => ({
                    ...prev,
                    welcome: data.data || {},
                }));
                console.log(data.data);
            }
        }

        const fetchGoodbyeData = async () => {
            const { data } = await api.get(`/goodbye/${guildId}`);
            if (data?.status === 'success') {
                setExtensionsData((prev: any) => ({
                    ...prev,
                    goodbye: data.data || {},
                }));
                console.log(data.data);
            }
        }

        fetchWelcomeData();
        fetchGoodbyeData();
    }, [guildId]);

    return (
        <div className='flex flex-col'>
            <h1 className='text-2xl font-semibold text-white'>Dashboard</h1>

            <div className='flex items-center flex-wrap mt-6 gap-x-9 gap-y-6'>
                <Link to={`/${guildId}/welcome`}>
                    <button className='w-56 h-56 flex flex-col gap-y-1 rounded-lg p-4 bg-[#1F2128] hover:bg-[#17181E] text-left'>
                        <CircleFadingPlus size={32} strokeWidth={1.5} className='text-[#7FCEF2]' />
                        <h2 className='text-white text-base mt-2 font-normal'>Welcome</h2>
                        <p className='text-sm'>Greet new members with a welcome message and roles.</p>

                        {extensionsData.welcome?.active ? (
                            <button className='bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-3 text-xs rounded mt-4'>
                                Active
                            </button>
                        ) : (
                            <button className='bg-gray-500 text-white font-bold py-2 px-3 text-xs rounded mt-4'>
                                Inactive
                            </button>
                        )}
                    </button>
                </Link>

                <Link to={`/${guildId}/goodbye`}>
                    <button className='w-56 h-56 flex flex-col gap-y-1 rounded-lg p-4 bg-[#1F2128] hover:bg-[#17181E] text-left'>
                        <Merge size={32} strokeWidth={1.5} className='text-[#7FCEF2] rotate-90' />
                        <h2 className='text-white text-base mt-2 font-normal'>Goodbye</h2>
                        <p className='text-sm'>Send a goodbye message when a member leaves.</p>

                        {extensionsData.goodbye?.active ? (
                            <button className='bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-3 text-xs rounded mt-4'>
                                Active
                            </button>
                        ) : (
                            <button className='bg-gray-500 text-white font-bold py-2 px-3 text-xs rounded mt-4'>
                                Inactive
                            </button>
                        )}
                    </button>
                </Link>
            </div>
        </div>
    );
}

export default ServerDetailPage;