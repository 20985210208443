import React, { useState, ChangeEvent } from 'react';
import { MentionsInput, Mention, SuggestionDataItem } from 'react-mentions';

interface User {
    id: string;
    display: string;
}

interface Props {
    value: string;
    onChange: (value: string) => void;
    placeholder?: string;
}

const CodeMentionInput: React.FC<Props> = ({ value, onChange, placeholder }) => {
    const users: User[] = [
        { id: 'user.name', display: 'User Name' },
        { id: 'server.member_count', display: 'Server Member Count' },
        { id: 'server.name', display: 'Server Name' },
    ];

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        onChange(event.target.value);
    };

    return (
        <>
            <MentionsInput
                singleLine
                value={value}
                onChange={(e: any) => handleChange(e)}
                placeholder={placeholder || "Hey {user.name}, welcome to {server.name}!"}
                className='code-mention'
            >
                <Mention
                    trigger="{"
                    data={users}
                    markup="{__id__}"
                    renderSuggestion={(suggestion: SuggestionDataItem, search: string, highlightedDisplay: React.ReactNode, index: number, focused: boolean) => (
                        <div className={`flex flex-col bg-[#17181b] text-gray-400 px-3 py-1 text-base ${focused ? 'bg-[#2f3136] text-white' : ''}`}>
                            <span className='text-sm text-gray-200'>{'{'}{suggestion.id}{'}'}</span>
                            <span className='text-sm'>- {highlightedDisplay}</span>
                        </div>
                    )}
                    displayTransform={(id: string, display: string) => `{${id}}`}
                    appendSpaceOnAdd
                />
            </MentionsInput>
        </>
    );
};

export default CodeMentionInput;