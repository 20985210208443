import axios from "axios";
import { toast } from "react-toastify";

const dev = process.env.NODE_ENV === 'development';
let showedError = false;

export const instance = axios.create({
    // baseURL: dev ? "http://localhost:8000/api/" : "https://api.lexitranslate.com/",
    baseURL: dev ? "http://localhost:8000/api/" : "https://api.lexitranslate.com/",
    timeout: 30000,
    headers: {},
    withCredentials: true,
});

// We are using session, don't need to use headers or JWT
instance.interceptors.request.use((config) => {
    return config;
});

instance.interceptors.response.use(
    (response) => {
        const data = response.data;
        if(data?.status === 'error' && data?.message) {
            toast.error(data.message);
        }

        if (data?.status === 'error' && data?.data?.callback) {
            window.location.href = data.data.callback;
            return response;
        }

        return response;
    },
    (error) => {
        if (!showedError) {
            toast.error('Network error. Please try again later.');
            showedError = true;

            setTimeout(() => {
                showedError = false;
            }, 5000);
        }

        console.error(error);
        return error;
    }
);

const api = instance;
export default api;