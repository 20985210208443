import { AlignJustify, ArrowBigUpDash, ArrowUpNarrowWide, ArrowUpWideNarrow, BotMessageSquare, Cloud, Flame, Focus, HandHeart, MousePointerClick, RefreshCcw, Settings, Sparkles, SquareDashedMousePointer } from 'lucide-react';
import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import api from '../../utils/api';

export default function HomePage() {
    const navigate = useNavigate();
    
    const goChooseServer = async () => {
        const { data } = await api.get('/discord/guilds');
        if(data?.status === 'success' && data?.data?.guilds) {
            navigate('/choose-server');
        }
    }

    return (
        <div className='flex flex-col bg-[#12141D] text-[#87888D]'>
            <nav className="bg-[#1E212D]">
                <div className="flex items-center justify-between px-4 py-4 container mx-auto">
                    <Link to="/" className="flex items-center gap-2">
                        <img src="logo.png" alt="Lexi Translate Logo" className="h-12 inline-block mr-2" />
                    </Link>
                    <div className="flex items-center gap-8">
                        <button
                            onClick={goChooseServer}
                            className="bg-blue-600 hover:bg-blue-500 text-white py-2 px-4 rounded-lg text-base font-semibold">
                            Add to Discord
                        </button>
                    </div>
                </div>
            </nav>

            <div className="flex flex-col container px-4 mx-auto">
                <div className="flex flex-col items-center justify-center mx-auto text-center md:max-w-[45rem] py-24 gap-y-4">
                    <h1 className="flex flex-col text-4xl md:text-6xl text-white font-semibold">
                        <b className="text-blue-500">Lexi Translate:</b>
                        Overcoming
                        Language
                        Barriers Instantly</h1>
                    <p className="text-lg md:text-xl">Experience seamless communication across over 200 languages with Lexi
                        Translate, your
                        free,
                        easy-to-use translation bot on Discord. Just react with a flag emoji to translate instantly!</p>

                    <div className="flex flex-col md:flex-row gap-4">
                        <button
                            onClick={goChooseServer}
                            className="bg-blue-600 hover:bg-blue-500 text-white py-3 px-6 rounded-lg text-base md:text-lg font-semibold">
                            Add to Discord
                        </button>
                    </div>
                </div>
            </div>

            <div className="flex flex-col px-4 container mx-auto">
                <div className="flex flex-col items-center justify-center mx-auto text-center md:max-w-[45rem] pt-24 pb-10 gap-y-4">
                    <h2 className="text-2xl md:text-4xl text-white font-semibold">Why <b className="text-blue-500">Lexi Translate?</b>
                    </h2>
                    <p className="text-base md:text-lg">
                        While major bots often limit customization and charge over $10 monthly for minimal personalization, we offer everything for free and aim to make every aspect customizable in the future.
                    </p>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-y-16 bg-[#1E212D] p-10 lg:p-20 rounded-lg">
                    <div className="flex flex-col md:w-10/12 lg:w-8/12 gap-y-2">
                        <HandHeart size={32} strokeWidth={1.25} className="text-red-500" />
                        <h3 className="text-xl text-red-500 font-semibold">Free</h3>
                        <p>Lexi Translate is free to use, with no hidden costs or premium plans.</p>
                    </div>
                    <div className="flex flex-col md:w-10/12 lg:w-8/12 gap-y-2">
                        <MousePointerClick size={32} strokeWidth={1.25} className="text-green-500" />
                        <h3 className="text-xl text-green-500 font-semibold">Simple and Efficient</h3>
                        <p>Lexi Translate operates effortlessly. Just click on a flag emoji, and the translation will be delivered to you using advanced AI technology. It's that simple!</p>
                    </div>
                    <div className="flex flex-col md:w-10/12 lg:w-8/12 gap-y-2">
                        <Settings size={32} strokeWidth={1.25} className="text-yellow-500" />
                        <h3 className="text-xl text-yellow-500 font-semibold">Customizable Dashboard</h3>
                        <p>With our dashboard, you can personalize welcome and goodbye messages and cards to fit your needs. And that's just the beginning!</p>
                    </div>

                    <div className="flex flex-col md:w-10/12 lg:w-8/12 gap-y-2">
                        <SquareDashedMousePointer size={32} strokeWidth={1.25} className="text-blue-500" />
                        <h3 className="text-xl text-blue-500 font-semibold">Focus on Personalization</h3>
                        <p>Our goal is to offer more features that can be personalized on an individual basis, making Lexi Translate user-friendly and adaptable to your preferences.</p>
                    </div>
                    <div className="flex flex-col md:w-10/12 lg:w-8/12 gap-y-2">
                        <BotMessageSquare size={32} strokeWidth={1.25} className="text-white" />
                        <h3 className="text-xl text-white font-semibold">AI-Based</h3>
                        <p>Lexi Translate uses AI to provide accurate translations, so you can trust the
                            translations you receive.</p>
                    </div>
                    <div className="flex flex-col md:w-10/12 lg:w-8/12 gap-y-2">
                        <ArrowUpWideNarrow size={32} strokeWidth={1.25} className="text-pink-500" />
                        <h3 className="text-xl text-pink-500 font-semibold">To Do All</h3>
                        <p>Lexi Translate will become a bot that can do everything you need, from moderation to music, all in one place.</p>
                    </div>
                </div>
            </div>

            <footer className="bg-[#1E212D] text-[#87888D] mt-24">
                <div className="container mx-auto py-4 px-4">
                    <p className="text-sm text-center">© 2024 Lexi Translate. All rights reserved.</p>
                </div>
            </footer>
        </div>
    )
}