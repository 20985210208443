import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { fetchGuilds } from '../services/guildService';
import api from '../utils/api';
import { Image } from 'lucide-react';
import { Button } from 'primereact/button';

const ChooseServer = () => {
    const navigate = useNavigate();
    const [guilds, setGuilds] = useState<IGuild[]>([]);

    useEffect(() => {
        const _fetchGuilds = async () => {
            const { data } = await api.get('/discord/guilds');
            if (data?.status === 'success' && data?.data?.guilds) {
                setGuilds(data.data.guilds);
            }
        }

        if (window.location.href.includes('?cb=success')) {
            window.close();
        }

        _fetchGuilds();
    }, []);

    const handleSetup = async (guild: IGuild) => {
        const newWindow = window.open(guild?.invite_url || '#', 'targetWindow', 'toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=500,height=800');

        if (newWindow) {
            newWindow.focus();

            const interval = setInterval(() => {
                try {
                    if (newWindow.location.origin === window.location.origin) {
                        clearInterval(interval);
                        newWindow.close();
                        navigate(`/${guild?.id}`);
                    }
                } catch (error) {
                }
            }, 1000);
        }
    }

    return (
        <div className='flex flex-col container py-24'>
            <h1 className='text-white text-3xl text-left underline'>Select a server</h1>

            <div className='flex items-center flex-wrap mt-12 gap-x-12 gap-y-6'>
                {/* id'ye göre */}
                {guilds?.sort((a, b) => a.id.localeCompare(b.id))?.map((guild, i) => (
                    <div key={i} className='flex flex-col w-80 lg:w-60 xl:w-72 mx-auto lg:mx-0'>
                        <div className='flex flex-col items-center justify-center w-full h-44 bg-gray-700/30 rounded-lg mb-3'>
                            {guild?.icon ? (
                                <div className='flex items-center justify-center  w-20 h-20 bg-white/5 rounded-full p-3 shadow'>
                                    <img
                                        src={`https://cdn.discordapp.com/icons/${guild?.id}/${guild?.icon}.png`}
                                        alt={guild?.name}
                                        className='w-full h-full object-cover rounded-full'
                                    />
                                </div>
                            ) : (
                                <div className='flex items-center justify-center w-20 h-20 bg-white/5 rounded-full p-3 shadow'>
                                    <Image size={32} className='text-white/50' />
                                </div>
                            )}
                        </div>
                        <div className='flex items-center justify-between'>
                            <div className='flex flex-col'>
                                <h2 className='text-white text-lg'>{guild?.name}</h2>
                                <span className='text-gray-400 text-sm'>{guild?.owner ? 'Owner' : 'Bot master'}</span>
                            </div>

                            {guild?.bot_joined ? (
                                <Link to={`/${guild?.id}`}>
                                    <button
                                        className='bg-white hover:bg-gray-300 text-black font-bold py-2 px-4 rounded'
                                    >
                                        Go
                                    </button>
                                </Link>
                            ) : (
                                <button
                                    className='bg-gray-700 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded'
                                    onClick={() => handleSetup(guild)}
                                >
                                    Setup
                                </button>
                            )}
                        </div>
                    </div>
                ))}
            </div>
        </div >
    );
}

export default ChooseServer;