import { ChevronDown, Hand, Plus, Settings, UserCheck, UserMinus, UserPlus } from 'lucide-react';
import React, { useEffect, useState } from 'react'
import OutsideClickHandler from 'react-outside-click-handler';
import api from '../../utils/api';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';

const Sidebar = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { guildId } = useParams();

    const [guild, setGuild] = useState<IGuild | null>(null);
    const [selectGuildDropdownVisible, setSelectGuildDropdownVisible] = useState(false);
    const [activeTab, setActiveTab] = useState('');

    const [guilds, setGuilds] = useState<IGuild[]>([]);

    useEffect(() => {
        const _fetchGuilds = async () => {
            const { data } = await api.get('/discord/guilds');
            if (data?.status === 'success' && data?.data?.guilds) {
                setGuilds(data.data.guilds);
                setGuild(data.data.guilds.find((guild: IGuild) => guild.id === guildId) || null);
            }
        }

        setActiveTab(location.pathname.split('/')[2]);

        _fetchGuilds();
    }, [window.location.href]);

    return (
        <div className='relative bg-[#1F2128] w-72 min-h-screen px-6 py-2 z-50'>
            <div className='flex flex-col text-sm'>
                <Link to={`/${guild?.id}`}>
                    <h2 className='text-white text-xl font-medium mt-4 px-2 mb-2 text-center'>Lexi Translate</h2>
                    <img
                        src="https://lexitranslate.com/logo.png"
                        alt="lexi translate dashboard"
                        className='w-20 h-20 rounded-full mx-auto mb-6'
                    />
                </Link>

                <h3 className='text-white text-xs font-medium px-2 mb-1'>Your Servers</h3>
                <OutsideClickHandler onOutsideClick={() => setSelectGuildDropdownVisible(false)}>
                    <div
                        className='relative flex items-center px-4 py-3 bg-black/20 rounded-lg cursor-pointer hover:shadow-lg gap-x-2'
                        onClick={() => setSelectGuildDropdownVisible(!selectGuildDropdownVisible)}
                    >
                        {guild?.icon ? (
                            <img
                                src={`https://cdn.discordapp.com/icons/${guild?.id}/${guild?.icon}.png`}
                                alt={guild?.name}
                                className='w-6 h-6 rounded-full'
                            />
                        ) : (
                            <div className='w-6 h-6 rounded-full bg-white/5' />
                        )}
                        <span className='text-white text-sm font-semibold'>{guild?.name}</span>
                        <ChevronDown size={16} className={`ml-auto text-white ${selectGuildDropdownVisible ? 'transform rotate-180' : ''}`} />

                        {selectGuildDropdownVisible && (
                            <div className='absolute top-full left-0 w-full bg-[#191A1F] flex flex-col rounded-lg mt-1 shadow-lg'>
                                {guilds
                                    ?.filter(guild => guild?.bot_joined)
                                    ?.sort((a, b) => a.id.localeCompare(b.id))?.map((guild, i) => (
                                        <div
                                            key={i}
                                            className={`flex items-center p-2 gap-x-2 hover:bg-black/20 ${i === 0 ? 'rounded-t-lg' : ''}`}
                                            onClick={() => navigate(`/${guild?.id}`)}
                                        >
                                            {guild?.icon ? (
                                                <img
                                                    src={`https://cdn.discordapp.com/icons/${guild?.id}/${guild?.icon}.png`}
                                                    alt={guild?.name}
                                                    className='w-6 h-6 rounded-full'
                                                />
                                            ) : (
                                                <div className='w-6 h-6 rounded-full bg-white/5' />
                                            )}
                                            <span className='text-white text-sm font-light'>{guild?.name}</span>
                                        </div>
                                    ))}
                                <div
                                    className='flex items-center px-2 py-4 border-t border-white/10 gap-x-2 hover:bg-black/20 rounded-b-lg'
                                    onClick={() => navigate('/choose-server')}
                                >
                                    <Plus size={16} className='text-white' />
                                    <span className='text-white text-sm font-semibold'>Add New Server</span>
                                </div>
                            </div>
                        )}
                    </div>
                </OutsideClickHandler>

                <h2 className='text-white text-xs font-medium mt-4 px-2 mb-1'>Extensions</h2>
                <Link
                    to={`/${guild?.id}/welcome`}
                    className={`flex items-center gap-x-2 mb-1 hover:text-white hover:bg-black/20 p-2 rounded ${activeTab === 'welcome' ? '!text-white bg-black/20' : ''}`}
                >
                    <UserPlus size={20} />
                    <span>Welcome</span>
                </Link>
                <Link to={`/${guild?.id}/goodbye`} className={`flex items-center gap-x-2 mb-1 hover:text-white hover:bg-black/20 p-2 rounded ${activeTab === 'goodbye' ? '!text-white bg-black/20' : ''}`}>
                    <UserMinus size={20} />
                    <span>Goodbye</span>
                </Link>
            </div>
        </div>
    )
}

export default Sidebar;