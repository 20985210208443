import React, { useEffect } from 'react';                     //icons
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import ServerDetailPage from './pages/ServerDetailPage';
import Layout from './components/Layout';
import WelcomeModulePage from './pages/modules/WelcomeModulePage';
import GoodbyeModulePage from './pages/modules/GoodbyeModulePage';
import api from './utils/api';
import { useDispatch } from 'react-redux';
import { setUser } from './store/userSlice';
import ChooseServer from './pages/ChooseServer';
import HomePage from './pages/standard/HomePage';
import { ToastContainer } from 'react-toastify';

function App() {
  return (
    <>
      <Router>
        <ToastContainer theme='dark' />
        
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/choose-server" element={<ChooseServer />} />

          <Route element={<Layout />}>
            <Route path="/:guildId" element={<ServerDetailPage />} />
            <Route path="/:guildId/welcome" element={<WelcomeModulePage />} />
            <Route path="/:guildId/goodbye" element={<GoodbyeModulePage />} />
          </Route>
        </Routes>
      </Router>
    </>
  );
}

export default App;
