import React, { useEffect } from 'react';
import Navbar from './Navbar';
import Sidebar from './Sidebar';
import { Outlet, useLocation } from 'react-router-dom';
import { setUser } from '../../store/userSlice';
import api from '../../utils/api';
import { useDispatch } from 'react-redux';
import { clearGuildId, setGuildId } from '../../store/dashboardSlice';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Layout = () => {
    const dispatch = useDispatch();
    const location = useLocation();

    useEffect(() => {
        const fetchUser = async () => {
            const { data } = await api.get('/discord/user');
            if (data?.status === 'success' && data?.data?.user) {
                dispatch(setUser(data.data.user));
            }
        }

        const guildId = location?.pathname?.split('/')[1];
        if (guildId) {
            dispatch(setGuildId(guildId));
        } else {
            dispatch(clearGuildId());
        }
        fetchUser();
    }, [dispatch]);

    return (
        <div className='flex flex-1'>
            <Sidebar />
            <div className='flex flex-col flex-1'>
                <Navbar />

                <div className='overflow-y-auto p-8' style={{ height: 'calc(100vh - 52px)' }}>
                    <Outlet />
                </div>
            </div>
        </div>
    );
}

export default Layout;