import { Check, Circle } from 'lucide-react'
import { ToggleButton } from 'primereact/togglebutton'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import api from '../../utils/api'
import { Dropdown } from 'primereact/dropdown'
import CodeMentionInput from '../../components/Dashboard/CodeMentionInput'
// import { cardBackgroundImages } from './WelcomeModulePage'

// Goodbye Module Page
const GoodbyeModulePage = () => {
    const { guildId } = useParams();

    // states and functions here
    const [noChangedGoodbyeModule, setNoChangedGoodbyeModule] = useState<IGoodbye | null>(null);
    const [goodbyeModule, setGoodbyeModule] = useState<IGoodbye | null>(null);
    const [channels, setChannels] = useState<IChannel[]>([]);
    const [cardBackgroundImageUrl, setCardBackgroundImageUrl] = useState('https://pikwizard.com/pw/medium/409f8182acbb8b040fab1a43720be4e3.jpg');

    useEffect(() => {
        const fetchModule = async () => {
            const { data } = await api.get(`/goodbye/${guildId}`);
            if (data?.status === 'success') {
                setGoodbyeModule(data.data || {});
                setNoChangedGoodbyeModule(data.data || {});
            }
        }

        const fetchChannels = async () => {
            const { data } = await api.get(`/discord/channels/${guildId}`);
            if (data?.status === 'success') {
                setChannels(data?.data?.channels || []);
            }
        }

        fetchModule();
        fetchChannels();
    }, []);

    const handleSave = async () => {
        const payload = {
            guildId,
            ...goodbyeModule || {},
        }

        const { data } = await api.post(`/goodbye`, payload);
        if (data?.status === 'success') {
            setNoChangedGoodbyeModule(goodbyeModule);
            console.log('Saved successfully');
        }
    }

    return (
        <div className='flex flex-col'>
            <div className='flex items-center justify-between'>
                <div className='flex flex-col'>
                    <h1 className='text-2xl font-semibold text-white'>Goodbye</h1>
                    <p className='text-gray-400 mt-1 border-b pb-4 border-white/10'>Send a goodbye message when members leave the server.</p>
                </div>

                <ToggleButton
                    onLabel='Enabled'
                    offLabel='Disabled'
                    onIcon={<Check size={18} className='mr-2' />}
                    offIcon={<Circle size={18} className='mr-2' />}
                    checked={goodbyeModule?.active || false}
                    onChange={() => setGoodbyeModule({ ...goodbyeModule, active: !goodbyeModule?.active })}
                />
            </div>

            {/* Send a message to selected channel when a member leaves */}
            <div className='flex flex-col p-4 rounded-lg bg-[#1F2128] mt-6'>
                <div className='flex items-center justify-between'>
                    <h2 className='text-base !font-normal text-white'>Send a message to selected channel when a member leaves</h2>
                    <ToggleButton
                        onLabel='Enabled'
                        offLabel='Disabled'
                        onIcon={<Check size={18} className='mr-2' />}
                        offIcon={<Circle size={18} className='mr-2' />}
                        checked={goodbyeModule?.sendMessageWhenUserLeave?.active || false}
                        onChange={() => setGoodbyeModule({ ...goodbyeModule, sendMessageWhenUserLeave: { ...goodbyeModule?.sendMessageWhenUserLeave, active: !goodbyeModule?.sendMessageWhenUserLeave?.active } })}
                    />
                </div>

                <div className='flex flex-col mt-4 max-w-[420px] gap-y-1'>
                    <label className='text-white text-sm'>Channel</label>
                    <Dropdown
                        value={goodbyeModule?.sendMessageWhenUserLeave?.targetChannelId}
                        options={channels?.map(channel => ({ label: '#' + channel.name, value: channel.id }))}
                        onChange={(e) => setGoodbyeModule({ ...goodbyeModule, sendMessageWhenUserLeave: { ...goodbyeModule?.sendMessageWhenUserLeave, targetChannelId: e.value } })}
                        placeholder='Select a channel'
                    />

                    <label className='text-white text-sm mt-2'>Message</label>
                    <CodeMentionInput
                        value={goodbyeModule?.sendMessageWhenUserLeave?.plainText || ''}
                        onChange={(value) => setGoodbyeModule({ ...goodbyeModule, sendMessageWhenUserLeave: { ...goodbyeModule?.sendMessageWhenUserLeave, plainText: value } })}
                        placeholder='Goodbye {user.name}!'
                    />
                </div>

                <div className='flex items-center justify-between mt-6'>
                    <h2 className='text-base !font-normal text-white'>Send a card when a member leaves</h2>
                    <ToggleButton
                        onLabel='Enabled'
                        offLabel='Disabled'
                        onIcon={<Check size={18} className='mr-2' />}
                        offIcon={<Circle size={18} className='mr-2' />}
                        checked={goodbyeModule?.sendMessageWhenUserLeave?.card?.active || false}
                        onChange={() => setGoodbyeModule({ ...goodbyeModule, sendMessageWhenUserLeave: { ...goodbyeModule?.sendMessageWhenUserLeave, card: { ...goodbyeModule?.sendMessageWhenUserLeave?.card, active: !goodbyeModule?.sendMessageWhenUserLeave?.card?.active } } })}
                    />
                </div>

                {goodbyeModule?.sendMessageWhenUserLeave?.active && (
                    <>
                        <div className='flex flex-wrap justify-between gap-x-6'>
                            <div className="relative flex flex-col mt-4 bg-black p-1 max-w-[340px] rounded-lg" style={{ zoom: 1.25 }}>
                                <img
                                    className="absolute top-0 left-0 w-full h-full rounded-lg brightness-150"
                                    src={cardBackgroundImageUrl}
                                    alt="background"
                                />

                                <div className='flex flex-col p-3 z-10 rounded-md bg-white/10 border border-white/10'>
                                    <div className="flex items-center gap-x-4">
                                        <img
                                            className="rounded-full w-12 h-12"
                                            src="https://cdn.discordapp.com/avatars/344474986435575809/01e684097f6a0e2537a598641abaa401.webp?size=512"
                                            alt="lexitranslate dashboard"
                                        />
                                        <div className="flex flex-col">
                                            <span className="text-white font-semibold text-xl">Reayot</span>
                                            <span className="text-white/70 text-xs">#0001</span>
                                        </div>
                                    </div>

                                    <div className="flex flex-col mt-4">
                                        <span className="text-white font-normal text-sm">Goodbye</span>
                                        <span className="text-white/70 text-xs">Good luck on your journey!</span>
                                    </div>
                                </div>
                            </div>

                            {/* Preview, Demo (Ready) images selectable */}
                            {/* <div className='flex items-center justify-between mt-4'>
                                <div className='flex gap-x-4'>
                                    {cardBackgroundImages.map((url, i) => (
                                        <button className='relative' key={i} onClick={() => setCardBackgroundImageUrl(url)}>
                                            <div className={`absolute top-0 left-0 w-full h-full rounded-lg ${cardBackgroundImageUrl === url ? '' : 'bg-black/40'}`} />
                                            <img
                                                key={i}
                                                src={url}
                                                alt='preview'
                                                className='h-24 rounded-lg cursor-pointer'
                                            />
                                        </button>
                                    ))}
                                </div>
                            </div> */}
                        </div>
                        <div className='flex flex-col mt-4 max-w-[420px] gap-y-1'>
                            <label className='text-white text-sm mt-2'>Title</label>
                            <input
                                type='text'
                                value={goodbyeModule?.sendMessageWhenUserLeave?.card?.title || ''}
                                onChange={(e) => setGoodbyeModule({ ...goodbyeModule, sendMessageWhenUserLeave: { ...goodbyeModule?.sendMessageWhenUserLeave, card: { ...goodbyeModule?.sendMessageWhenUserLeave?.card, title: e.target.value } } })}
                                placeholder='Goodbye!'
                            />

                            <label className='text-white text-sm mt-2'>Description</label>
                            <CodeMentionInput
                                value={goodbyeModule?.sendMessageWhenUserLeave?.card?.description || ''}
                                onChange={(value) => setGoodbyeModule({ ...goodbyeModule, sendMessageWhenUserLeave: { ...goodbyeModule?.sendMessageWhenUserLeave, card: { ...goodbyeModule?.sendMessageWhenUserLeave?.card, description: value } } })}
                                placeholder='You are the {server.member_count}th member to join the server'
                            />
                        </div>
                    </>
                )}
            </div>

            <div className='mt-20'>
                {JSON.stringify(goodbyeModule) !== JSON.stringify(noChangedGoodbyeModule) && (
                    <div className='fixed left-0 bottom-0 w-full flex items-center justify-between bg-[#17181E] p-4 z-50'>
                        <span className='text-base'>You have unsaved changes</span>

                        <div className='flex items-center justify-end gap-x-6'>
                            {/* Discard and save buttons */}
                            <button className='text-gray-400 hover:text-gray-300' onClick={() => setGoodbyeModule(noChangedGoodbyeModule)}>
                                Discard
                            </button>

                            <button
                                className='bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-8 rounded'
                                onClick={handleSave}
                            >
                                Save
                            </button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default GoodbyeModulePage;