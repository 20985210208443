import { ChevronDown, Languages, LogOut, Server, Text } from 'lucide-react';
import React, { useEffect, useState } from 'react'
import OutsideClickHandler from 'react-outside-click-handler';
import api from '../../utils/api';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { Link } from 'react-router-dom';

const Navbar = () => {
    const user = useSelector((state: RootState) => state.user);
    const [visibleUserDropdown, setVisibleUserDropdown] = useState(false);

    const handleLogout = async () => {
        try {
            const { data } = await api.post('/auth/logout');
            if (data.status === 'success') window.location.href = '/';
        } catch (error) {
            console.error(error);
        }
    }

    return (
        <div className='bg-[#1F2128] z-30 sticky top-0'>
            <div className='flex items-center justify-end px-8 py-2'>
                <div className='relative'>
                    <OutsideClickHandler onOutsideClick={() => setVisibleUserDropdown(false)}>
                        <button className='flex items-center gap-x-2' onClick={() => setVisibleUserDropdown(!visibleUserDropdown)}>
                            <img
                                className='rounded-full w-9 h-9'
                                src={`https://cdn.discordapp.com/avatars/${user?.id}/${user?.avatar}.png?size=512`}
                                alt="lexitranslate dashboard"
                            />
                            <span className='text-white font-medium text-sm'>{user?.name}</span>
                            <ChevronDown size={16} className={`text-white ${visibleUserDropdown ? 'rotate-180' : ''}`} />
                        </button>

                        {visibleUserDropdown && (
                            <div className='absolute right-0 top-full mt-1 w-48 bg-[#17181E] rounded-lg shadow-lg text-sm'>
                                <Link to='/choose-server' className='flex items-center gap-x-2 p-2 hover:bg-black/20 hover:text-gray-300'>
                                    <Server size={16} />
                                    <span>My servers</span>
                                </Link>
                                <button className='flex items-center gap-x-2 p-2 hover:bg-black/20 hover:text-gray-300 w-full' onClick={handleLogout}>
                                    <LogOut size={16} />
                                    <span>Logout</span>
                                </button>
                            </div>
                        )}
                    </OutsideClickHandler>

                </div>
            </div>
        </div>
    )
}

export default Navbar;