import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface DashboardState {
  guildId: string | null;
}

const initialState: DashboardState = {
  guildId: null,
};

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    setGuildId(state, action: PayloadAction<string>) {
      state.guildId = action.payload;
    },
    clearGuildId(state) {
      state.guildId = null;
    },
  },
});

export const { setGuildId, clearGuildId } = dashboardSlice.actions;
export default dashboardSlice.reducer;
