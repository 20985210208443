import { Check, Circle, RefreshCcw, RefreshCw, RefreshCwOff } from 'lucide-react'
import { Button } from 'primereact/button'
import { FileUpload } from 'primereact/fileupload'
import { ToggleButton } from 'primereact/togglebutton'
import React, { useEffect, useState } from 'react'
import BasicDemo from '../../components/BasicDemo'
import { useParams } from 'react-router-dom'
import api from '../../utils/api'
import { Dropdown } from 'primereact/dropdown'
import CodeMentionInput from '../../components/Dashboard/CodeMentionInput'
import { MultiSelect } from 'primereact/multiselect'

// ready card background images
export const cardBackgroundImages = [
    'https://pikwizard.com/pw/medium/409f8182acbb8b040fab1a43720be4e3.jpg',
    'https://static.vecteezy.com/system/resources/thumbnails/025/067/762/small_2x/4k-beautiful-colorful-abstract-wallpaper-photo.jpg',
    'https://img.freepik.com/free-photo/digital-art-beautiful-mountains_23-2151123686.jpg',
    'https://t3.ftcdn.net/jpg/05/85/86/44/360_F_585864419_kgIYUcDQ0yiLOCo1aRjeu7kRxndcoitz.jpg',
    'https://wallpapers.com/images/featured/4k-space-9w27dqmc4nrs3xwd.jpg',
    'https://c4.wallpaperflare.com/wallpaper/363/13/252/valorant-killjoy-valorant-digital-art-artwork-digital-hd-wallpaper-preview.jpg',
    'https://wallpapercave.com/wp/wp8101024.jpg',
    'https://cdn.pixabay.com/photo/2022/03/01/02/51/galaxy-7040416_1280.png',
    'https://t4.ftcdn.net/jpg/06/07/15/45/360_F_607154571_OpIJsl9NIQjKHZEVPHOkcaqCpEyxp0xh.jpg'
];

const WelcomeModulePage = () => {
    const { guildId } = useParams();

    // states and functions here
    const [noChangedWelcomeModule, setNoChangedWelcomeModule] = useState<IWelcome | null>(null);
    const [welcomeModule, setWelcomeModule] = useState<IWelcome | null>(null);
    const [roles, setRoles] = useState<any[]>([]);
    const [channels, setChannels] = useState<IChannel[]>([]);

    useEffect(() => {
        const fetchModule = async () => {
            const { data } = await api.get(`/welcome/${guildId}`);
            if (data?.status === 'success') {
                setWelcomeModule(data.data || {});
                setNoChangedWelcomeModule(data.data || {});
            }
        }

        const fetchRoles = async () => {
            const { data } = await api.get(`/discord/roles/${guildId}`);
            if (data?.status === 'success') {
                setRoles(data?.data?.roles || []);
            }
        }

        const fetchChannels = async () => {
            const { data } = await api.get(`/discord/channels/${guildId}`);
            if (data?.status === 'success') {
                setChannels(data?.data?.channels || []);
            }
        }

        fetchModule();
        fetchRoles();
        fetchChannels();
    }, []);

    const handleSave = async () => {
        const payload = {
            guildId,
            ...welcomeModule || {},
        }

        const { data } = await api.post(`/welcome`, payload);
        if (data?.status === 'success') {
            setNoChangedWelcomeModule(welcomeModule);
            console.log('Saved successfully');
        }
    }

    const handleFileSelect = (e: any) => {
        const file = e.files[0];
        const reader = new FileReader();

        reader.onload = (e) => {
            // setCardBackgroundImageUrl(e.target?.result as string);
        }

        reader.readAsDataURL(file);
    }

    return (
        <div className='flex flex-col'>
            <div className='flex items-center justify-between'>
                <div className='flex flex-col'>
                    <h1 className='text-2xl font-semibold text-white'>Welcome</h1>
                    <p className='text-gray-400 mt-1 border-b pb-4 border-white/10'>Greet new members with a welcome message and roles.</p>
                </div>

                <ToggleButton
                    onLabel='Enabled'
                    offLabel='Disabled'
                    onIcon={<Check size={18} className='mr-2' />}
                    offIcon={<Circle size={18} className='mr-2' />}
                    checked={welcomeModule?.active || false}
                    onChange={() => setWelcomeModule({ ...welcomeModule, active: !welcomeModule?.active })}
                />
            </div>

            {/* Give a role to new members */}
            <div className='flex flex-col p-4 rounded-lg bg-[#1F2128] mt-6'>
                <div className='flex items-center justify-between'>
                    <h2 className='text-base !font-normal text-white'>Give a role to new members</h2>
                    <ToggleButton
                        onLabel='Enabled'
                        offLabel='Disabled'
                        onIcon={<Check size={18} className='mr-2' />}
                        offIcon={<Circle size={18} className='mr-2' />}
                        checked={welcomeModule?.giveRoleWhenUserJoin?.active || false}
                        onChange={() => setWelcomeModule({ ...welcomeModule, giveRoleWhenUserJoin: { ...welcomeModule?.giveRoleWhenUserJoin, active: !welcomeModule?.giveRoleWhenUserJoin?.active } })}
                    />
                </div>

                <div className='flex flex-col mt-4 max-w-[420px] gap-y-1'>
                    <label className='text-white text-sm'>Roles</label>
                    {/* <Dropdown
                        value={welcomeModule?.giveRoleWhenUserJoin?.idOfRoles}
                        options={roles?.map(role => ({ label: role.name, value: role.id }))}
                        onChange={(e) => setWelcomeModule({ ...welcomeModule, giveRoleWhenUserJoin: { ...welcomeModule?.giveRoleWhenUserJoin, idOfRoles: e.value } })}
                        placeholder='Select roles'
                        multiple
                    /> */}

                    <MultiSelect
                        value={welcomeModule?.giveRoleWhenUserJoin?.idOfRoles}
                        options={roles?.map(role => ({ label: role.name, value: role.id, color: role.color }))}
                        onChange={(e) => setWelcomeModule({ ...welcomeModule, giveRoleWhenUserJoin: { ...welcomeModule?.giveRoleWhenUserJoin, idOfRoles: e.value } })}
                        placeholder='Select roles'
                        display='chip'
                        itemTemplate={(option) => <span style={{ color: option?.color }}>{option?.label}</span>}
                        showClear
                    />
                </div>
            </div>

            {/* Send a message to selected channel when a new member joins */}
            <div className='flex flex-col p-4 rounded-lg bg-[#1F2128] mt-6'>
                <div className='flex items-center justify-between'>
                    <h2 className='text-base !font-normal text-white'>Send a message to selected channel when a new member joins</h2>
                    <ToggleButton
                        onLabel='Enabled'
                        offLabel='Disabled'
                        onIcon={<Check size={18} className='mr-2' />}
                        offIcon={<Circle size={18} className='mr-2' />}
                        checked={welcomeModule?.sendMessageWhenUserJoin?.active || false}
                        onChange={() => setWelcomeModule({ ...welcomeModule, sendMessageWhenUserJoin: { ...welcomeModule?.sendMessageWhenUserJoin, active: !welcomeModule?.sendMessageWhenUserJoin?.active } })}
                    />
                </div>

                <div className='flex flex-col mt-4 max-w-[420px] gap-y-1'>
                    <label className='text-white text-sm'>Channel</label>
                    <Dropdown
                        value={welcomeModule?.sendMessageWhenUserJoin?.targetChannelId}
                        options={channels?.map(channel => ({ label: '#' + channel.name, value: channel.id }))}
                        onChange={(e) => setWelcomeModule({ ...welcomeModule, sendMessageWhenUserJoin: { ...welcomeModule?.sendMessageWhenUserJoin, targetChannelId: e.value } })}
                        placeholder='Select a channel'
                    />

                    <label className='text-white text-sm mt-2'>Message</label>
                    <CodeMentionInput
                        value={welcomeModule?.sendMessageWhenUserJoin?.plainText || ''}
                        onChange={(value) => setWelcomeModule({ ...welcomeModule, sendMessageWhenUserJoin: { ...welcomeModule?.sendMessageWhenUserJoin, plainText: value } })}
                    />
                </div>

                <div className='flex items-center justify-between mt-6'>
                    <h2 className='text-base !font-normal text-white'>Send a welcome card to selected channel when a new member joins</h2>
                    <ToggleButton
                        onLabel='Enabled'
                        offLabel='Disabled'
                        onIcon={<Check size={18} className='mr-2' />}
                        offIcon={<Circle size={18} className='mr-2' />}
                        checked={welcomeModule?.sendMessageWhenUserJoin?.card?.active || false}
                        onChange={() => setWelcomeModule({ ...welcomeModule, sendMessageWhenUserJoin: { ...welcomeModule?.sendMessageWhenUserJoin, card: { ...welcomeModule?.sendMessageWhenUserJoin?.card, active: !welcomeModule?.sendMessageWhenUserJoin?.card?.active } } })}
                    />
                </div>

                {welcomeModule?.sendMessageWhenUserJoin?.active && (
                    <>
                        <div className='flex flex-col'>
                            <div className="relative flex flex-col mt-4 bg-black p-1 max-w-[300px] rounded-lg" style={{ zoom: 1.5 }}>
                                <img
                                    className="absolute top-0 left-0 w-full h-full rounded-lg brightness-150"
                                    src={welcomeModule?.sendMessageWhenUserJoin?.card?.imageUrl || cardBackgroundImages[0]}
                                    alt="background"
                                />

                                <div className='flex flex-col p-3 z-10 rounded-md bg-white/10 border border-white/10'>
                                    <div className="flex items-center gap-x-4">
                                        {/* <img
                                            className="rounded-full w-12 h-12"
                                            src="https://lexitranslate.com/logo.png"
                                            alt="lexitranslate dashboard"
                                        /> */}
                                        <div className="flex flex-col">
                                            <span className="text-white font-semibold text-xl">Lexi Translate</span>
                                            <span className="text-white/70 text-xs">Lexi Translate#7295</span>
                                        </div>
                                    </div>

                                    <div className="flex flex-col mt-4">
                                        <span className="text-white font-normal text-sm">Welcome to the server!</span>
                                        <span className="text-white/70 text-xs">You are the 3004th member to join the server</span>
                                    </div>
                                </div>
                            </div>

                            <h2 className='text-base !font-normal text-white mt-8 mb-4'>Select a background image</h2>

                            {/* Preview, Demo (Ready) images selectable */}
                            <div className='flex items-center justify-between'>
                                <div className='flex flex-wrap gap-4'>
                                    {cardBackgroundImages?.map((url, i) => (
                                        <button className='relative' key={i} onClick={() => setWelcomeModule({ ...welcomeModule, sendMessageWhenUserJoin: { ...welcomeModule?.sendMessageWhenUserJoin, card: { ...welcomeModule?.sendMessageWhenUserJoin?.card, imageUrl: url } } })}>
                                            <img
                                                key={i}
                                                src={url}
                                                alt='preview'
                                                className={`w-48 h-28 rounded-lg cursor-pointer ${welcomeModule?.sendMessageWhenUserJoin?.card?.imageUrl === url ? 'border-2 border-blue-500' : ''}`}
                                            />
                                        </button>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className='flex flex-col mt-4 max-w-[420px] gap-y-1'>
                            <label className='text-white text-sm mt-2'>Title</label>
                            <input
                                type='text'
                                value={welcomeModule?.sendMessageWhenUserJoin?.card?.title || ''}
                                onChange={(e) => setWelcomeModule({ ...welcomeModule, sendMessageWhenUserJoin: { ...welcomeModule?.sendMessageWhenUserJoin, card: { ...welcomeModule?.sendMessageWhenUserJoin?.card, title: e.target.value } } })}
                                placeholder='Welcome to the server!'
                            />

                            <label className='text-white text-sm mt-2'>Description</label>
                            <CodeMentionInput
                                value={welcomeModule?.sendMessageWhenUserJoin?.card?.description || ''}
                                onChange={(value) => setWelcomeModule({ ...welcomeModule, sendMessageWhenUserJoin: { ...welcomeModule?.sendMessageWhenUserJoin, card: { ...welcomeModule?.sendMessageWhenUserJoin?.card, description: value } } })}
                                placeholder='You are the {server.member_count}th member to join the server'
                            />
                        </div>
                    </>
                )}
            </div>

            {/* Send private message to new members */}
            <div className='flex flex-col p-4 rounded-lg bg-[#1F2128] mt-6'>
                <div className='flex items-center justify-between'>
                    <h2 className='text-base !font-normal text-white'>Send a private message to new members</h2>
                    <ToggleButton
                        onLabel='Enabled'
                        offLabel='Disabled'
                        onIcon={<Check size={18} className='mr-2' />}
                        offIcon={<Circle size={18} className='mr-2' />}
                        checked={welcomeModule?.sendPrivateMessageWhenUserJoin?.active || false}
                        onChange={() => setWelcomeModule({ ...welcomeModule, sendPrivateMessageWhenUserJoin: { ...welcomeModule?.sendPrivateMessageWhenUserJoin, active: !welcomeModule?.sendPrivateMessageWhenUserJoin?.active } })}
                    />
                </div>

                <div className='flex flex-col mt-4 max-w-[420px] gap-y-1'>
                    <label className='text-white text-sm'>Message</label>
                    <CodeMentionInput
                        value={welcomeModule?.sendPrivateMessageWhenUserJoin?.plainText || ''}
                        onChange={(value) => setWelcomeModule({ ...welcomeModule, sendPrivateMessageWhenUserJoin: { ...welcomeModule?.sendPrivateMessageWhenUserJoin, plainText: value } })}
                    />
                </div>
            </div>

            <div className='mt-20'>
                {JSON.stringify(welcomeModule) !== JSON.stringify(noChangedWelcomeModule) && (
                    <div className='fixed left-0 bottom-0 w-full flex items-center justify-between bg-[#17181E] p-4 z-50'>
                        <span className='text-base'>You have unsaved changes</span>

                        <div className='flex items-center justify-end gap-x-6'>
                            <button className='text-gray-400 hover:text-gray-300' onClick={() => setWelcomeModule(noChangedWelcomeModule)}>
                                Discard
                            </button>

                            <button
                                className='bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-8 rounded'
                                onClick={handleSave}
                            >
                                Save
                            </button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default WelcomeModulePage